import React from 'react';
import { Layout } from '../../../components/Layout';

const LargeBuildingConstruction = () => (
  <Layout
    title="Large Building Construction Calgary & Red Deer | Axiom Geomatics"
    description="Large Building Construction services include gridlines, pile and column surveys, dimensional controls, elevator shaft alignments and more."
    keywords="municipal survey, construction survey, gridlines, bolt patterns, dimensional control, shop drawing, parking lot survey, curb layout, as-built survey, as-located survey"
  >
    <main>
      <h1>Large Building Construction Calgary & Area</h1>
      <p>
        Staff at Axiom Geomatics have experience working in{' '}
        <strong>large-scale municipal survey projects</strong>
        including shopping complexes, multi-story commercial buildings, large parking lots and
        high-rise condominiums. We can work with your architectural and engineering teams from
        conception through to the final survey.
      </p>

      <p>Here are only some of the services we offer:</p>

      <ul>
        <li>Gridlines</li>
        <li>Pile and column surveys</li>
        <li>Bolt patterns</li>
        <li>Dimensional controls</li>
        <li>Shop drawings</li>
        <li>Elevator shaft alignment surveys</li>
        <li>Curb layout surveys</li>
        <li>Parking lot surveys</li>
      </ul>

      <p>Of course we also provide as-built and as-located surveys.</p>

      <p>
        We understand the unique nature of large-scale projects and are happy to accommodate other
        unique survey requests that would benefit your project.
      </p>

      <p>
        Get in touch with our Commercial & Industrial Services team to learn how we can help you get
        your project done more efficiently.
      </p>
    </main>
  </Layout>
);

export default LargeBuildingConstruction;
